import { CoreMenu } from '@core/types';
import { faAward, faBell, faBellConcierge, faChalkboardTeacher, faChartLine, faClipboardQuestion, faGear, faHome, faPenRuler, faRankingStar, faSchool, faSquarePollVertical, faTrophy, faUserCheck, faUserGear, faUserGraduate, faUsers } from '@fortawesome/free-solid-svg-icons';
import { appRoutesConstants } from 'app/@core/values/app-routes-constants';


export const menu: CoreMenu[] = [
  // {
  //   id: 'dashboard',
  //   type: 'item',
  //   title: 'Dashboard',
  //   translate: 'MENU.DASHBOARD',
  //   faIcon: faHome,
  //   url: appRoutesConstants.dashboard,
  // },
  // {
  //   id: 'landingPage',
  //   type: 'item',
  //   title: 'landingPage',
  //   translate: 'MENU.LANDING_PAGE',
  //   faIcon: faHome,
  //   url: appRoutesConstants.landingPage,
  // },
  // management
  // {
  //   id: 'notifications',
  //   title: 'notifications',
  //   translate: 'MENU.NOTIFICATIONS',
  //   type: 'item',
  //   faIcon: faBellConcierge,
  //   url: appRoutesConstants.usersNotifications,
  //   // role: [authenticationConstants.roles.admin]
  // },
  // {
  //   id: 'teachers',
  //   title: 'teachers',
  //   translate: 'MENU.TEACHERS',
  //   type: 'item',
  //   faIcon: faChalkboardTeacher,
  //   url: appRoutesConstants.teacherDashboard,
  //   // role: [authenticationConstants.roles.admin]
  // },
  // {
  //   id: 'students',
  //   title: 'students',
  //   translate: 'MENU.STUDENTS',
  //   type: 'item',
  //   faIcon: faUserGraduate,
  //   url: appRoutesConstants.studentDashboard,
  //   // role: [authenticationConstants.roles.admin]
  // },
  // {
  //   id: 'prizesWithLevels',
  //   title: 'prizesWithLevels',
  //   translate: 'MENU.PRIZES',
  //   type: 'item',
  //   faIcon: faAward,
  //   url: appRoutesConstants.prizesWithLevels,
  //   // role: [authenticationConstants.roles.admin]
  // },
  // {
  //   id: 'winners',
  //   title: 'winners',
  //   translate: 'MENU.WINNERS',
  //   type: 'item',
  //   faIcon: faRankingStar,
  //   url: appRoutesConstants.winners,
  //   // role: [authenticationConstants.roles.admin]
  // },
  // {
  //   id: 'instructions',
  //   title: 'instructions',
  //   translate: 'MENU.INSTRUCTIONS',
  //   type: 'item',
  //   faIcon: faPenRuler,
  //   url: appRoutesConstants.instructions,
  //   // role: [authenticationConstants.roles.admin]
  // },
];
// 1 - Do the menu buttons
// 2 - make a module for the users
// 3 - get the user type from the route 
// 4 - adjust the view according to the user type