import { environment } from "../../../environments/environment";

export const appConstants = {
  // ------------------------------- Values -------------------------------

  toastrConfig: {
    toastClass: "toast ngx-toastr",
    positionClass: "toast-top-right",
    closeButton: true,
    progressBar: true,
    tapToDismiss: true,
    newestOnTop: true,
  },
  flatpickrOptions: {
    altInput: true,
    enableTime: true,
    defaultDate: new Date(),
    // altFormat: environment.altDateFormat,
    // enableSeconds: true,
  },
  flatpickrOptionsNoDefaultDate: {
    altInput: true,
    altFormat: environment.altDateFormat,
    // enableTime: true,
    // enableSeconds: true,
  },
  genders: [
    {
      name: "ذكر",
      key: "male",
    },
    {
      name: "أُنثى",
      key: "female",
    },
  ],
  pageSizes: [15, 25, 50, 75, 100, 500],
  notDeleted: 'NOT_DELETED',
  defaultPageSize: 15,
  notFoundMessage: 'No data found',
  defaultCountryCode: '+966',

  // ------------------------------- Dictionaries -------------------------------

  appFeatures: {
    ALL_SYSTEM_FEATURE: "كامل البرنامج",
  },
  contatInfoTypes: {
    Facebook: "فيس بوك",
    Twitter: "تويتر",
    Whatsapp: "وتس أب",
    Instagram: "إنستجرام",
    LinkedIn: "LinkedIn",
    Phone: "هاتف",
    Fax: "فاكس",
    Website: "الموقع الإلكتروني",
    Email: "الإيميل",
    Youtube: "يوتيوب",
  },
};
