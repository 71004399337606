import { HTTP_INTERCEPTORS, HttpClientModule } from "@angular/common/http";
import { NgModule } from "@angular/core";
import { BrowserModule } from "@angular/platform-browser";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { RouterModule, Routes } from "@angular/router";

import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { TranslateModule } from "@ngx-translate/core";
import "hammerjs";
import { ToastrModule } from "ngx-toastr";

import { CoreCommonModule } from "@core/common.module";
import { CoreSidebarModule, CoreThemeCustomizerModule } from "@core/components";
import { CardSnippetModule } from "@core/components/card-snippet/card-snippet.module";
import { CoreModule } from "@core/core.module";

import { FormsModule } from "@angular/forms";
import { CoreCardModule } from "@core/components/core-card/core-card.module";
import { FontAwesomeModule } from "@fortawesome/angular-fontawesome";
import { coreConfig } from "app/app-config";
import { AppComponent } from "app/app.component";
import { ErrorInterceptor, JwtInterceptor } from "app/auth/helpers";
import { AuthGuard } from "app/auth/helpers/auth.guards";
import { ContentHeaderModule } from "app/layout/components/content-header/content-header.module";
import { LayoutModule } from "app/layout/layout.module";
import { NgxExtendedPdfViewerModule } from 'ngx-extended-pdf-viewer';
import { AppUtilsModule } from "./@core/utils/app-utils.module";
import { appRoutesConstants } from "./@core/values/app-routes-constants";
import { AppService } from "./app.service";
import { ComponentsModule } from "./components/components.module";

const routes: Routes = [
  {
    path: "",
    loadChildren: () =>
      import("./features/landing-page/landing-page-routing.module").then(
        (m) => m.LandingPageRoutingModule
      ),
  },
  {
    path: "miscellaneous",
    loadChildren: () =>
      import("./main/miscellaneous/miscellaneous.module").then(
        (c) => c.MiscellaneousModule
      ),
  },
  {
    path: "**",
    redirectTo: appRoutesConstants.pageNotFound, //Error 404 - Page not found
  },
];

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    FormsModule,
    RouterModule.forRoot(routes, {
      scrollPositionRestoration: "enabled",
    }),
    NgbModule,
    CoreCardModule,
    ToastrModule.forRoot(),
    TranslateModule.forRoot(),
    // ContextMenuModule,
    CoreModule.forRoot(coreConfig),
    CoreCommonModule,
    CoreSidebarModule,
    CoreThemeCustomizerModule,
    CardSnippetModule,
    LayoutModule,
    ContentHeaderModule,
    AppUtilsModule,
    FontAwesomeModule,
    ComponentsModule,
    NgxExtendedPdfViewerModule,
  ],
  providers: [
    AppService,

    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
    // ! IMPORTANT: Provider used to create fake backend, comment while using real API
    // fakeBackendProvider
  ],
  bootstrap: [AppComponent],
})
export class AppModule { }
