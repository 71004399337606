import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';

import { CoreCommonModule } from '@core/common.module';
import { CoreMenuModule } from '@core/components';

import { VerticalMenuComponent } from 'app/layout/components/menu/vertical-menu/vertical-menu.component';
import { NgScrollbarModule } from 'ngx-scrollbar';

@NgModule({
  declarations: [VerticalMenuComponent],
  imports: [
    CoreMenuModule, 
    CoreCommonModule,
    RouterModule,
    NgScrollbarModule,
  ],
  providers: [
  ],
  exports: [VerticalMenuComponent]
})
export class VerticalMenuModule {}
