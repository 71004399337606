export const locale = {
    lang: 'en',
    data: {
        MISCELLANEOUS: {
            HOME: 'Home',
            BRAND_LOGO: 'Brand Logo',
        },
        ERROR_PAGE:{
            TITLE: 'Page Not Found',
            TEXT: 'Sorry! 😖 The page you requested is not found on the server.',
            ERROR_PAGE_IMAGE_ALT: 'Error Page',
        },
        NOT_AUTHORIZED: {
            TITLE: 'Not Authorized',
            TEXT: 'You do not have permission to access this page.',
            NOT_AUTHORIZED_PAGE: 'Not Authorized Page'
        }
    }
}