import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';

import { environment } from 'environments/environment';
import { appConstants } from 'app/@core/values/app-constants';
import { appRoutesConstants } from 'app/@core/values/app-routes-constants';
import { Router } from '@angular/router';

@Injectable()
export class JwtInterceptor implements HttpInterceptor {
  /**
   *
   * @param {AuthService} _authService
   */
  constructor(
    private _router: Router,
  ) { }

  /**
   * Add auth header with jwt if user is logged in and request is to api url
   * @param request
   * @param next
   */
  intercept(
    request: HttpRequest<any>,
    next: HttpHandler,
  ): Observable<HttpEvent<any>> {
    // const currentUser = this._authService.currentUserValue;
    // const isLoggedIn = currentUser && currentUser.token;

    const isApiUrl = request.url.startsWith(environment.apiUrl);

    if (isApiUrl) {
      request = request.clone({
        setHeaders: {
          Authorization:`Bearer 107|7avwEBcnAbglqV1l0GoGMqDL8yZIYYh8YTraClewad535b3c`
        },
      });
    }
    
    return next.handle(request);
  }
}
