import { NgModule } from '@angular/core';

import { HighlightSearchTextPipe } from './highlight-search-text.pipe';
import { PreserveFormattingPipe } from './preserve-formatting.pipe';
import { SecondsToTimePipe } from './seconds-to-time.pipe';
import { UtcToLocalDateTimePipe } from './utc-to-local-date-time.pipe';
import { InitialsPipe } from './initials.pipe';
import { FilterPipe } from './filter.pipe';
import { StripHtmlPipe } from './stripHtml.pipe';
import { SafePipe } from './safe.pipe';
import { HideEmailPipe } from './hide-email.pipe';

@NgModule({
  declarations: [InitialsPipe, FilterPipe, StripHtmlPipe, SafePipe, HighlightSearchTextPipe, PreserveFormattingPipe, SecondsToTimePipe, UtcToLocalDateTimePipe, HideEmailPipe],
  imports: [],
  exports: [InitialsPipe, FilterPipe, StripHtmlPipe, SafePipe, HighlightSearchTextPipe, PreserveFormattingPipe, SecondsToTimePipe, UtcToLocalDateTimePipe, HideEmailPipe]
})
export class CorePipesModule {}
