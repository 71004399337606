<div *ngIf="horizontalMenu" class="navbar-header d-xl-block d-none">

  <!-- Navbar brand -->
  <ul class="nav navbar-nav flex-row">

    <li class="nav-item">
    
      <a class="navbar-brand" [routerLink]="['/']">
    
        <span class="brand-logo">
    
          <img src="{{ coreConfig.app.appLogoImage }}" alt="{{ layoutKeys.LOGOALT | translate }}" width="36" />
    
        </span>
    
        <h2 class="brand-text mb-0">{{ coreConfig.app.appName }}</h2>
    
      </a>
    
    </li>
  
  </ul>
  <!--/ Navbar brand -->

</div>

<div class="navbar-container d-flex content">

  <div class="bookmark-wrapper d-flex align-items-center">
  
    <!-- Menu Toggler | Menu icon will be hidden in case of layout without menu -->
    <ul class="nav navbar-nav" *ngIf="!coreConfig.layout.menu.hidden">
  
      <li class="nav-item d-xl-none">
  
        <a class="nav-link menu-toggle" (click)="toggleSidebar('menu')">
  
          <span [data-feather]="'menu'" [class]="'ficon'"></span>
  
        </a>
  
      </li>
  
    <!-- Search -->
    <!-- <app-navbar-search></app-navbar-search> -->
    <!--/ Search -->
    
    </ul>
    <!--/ Menu Toggler -->

  </div>

  <ul class="nav navbar-nav align-items-center ml-auto">

    <!-- User Dropdown -->
    <li ngbDropdown class="nav-item dropdown-user">
      
      <a id="dropdown-user" class="nav-link dropdown-toggle dropdown-user-link" ngbDropdownToggle
        aria-haspopup="true" aria-expanded="false">

      </a>

      <div ngbDropdownMenu aria-labelledby="navbar UserDropdown" class="dropdown-menu  dropdown-menu-alignment">

        <div ngbDropdownItem [routerLink]="[appRoutesConst.accountSettings]">
          <span [data-feather]="'settings'" [class]="'mr-50'"></span> 
            {{ layoutKeys.ACCOUNT_SETTINGS | translate }}
        </div>

        <!-- @if (currentSkin == 'dark') {
          <div ngbDropdownItem (click)="toggleDarkSkin()">
            <span [data-feather]="'sun'" class="mr-50"></span>
            {{ layoutKeys.LIGHT_MODE | translate }}
          </div>
        }
        @else {
          <div ngbDropdownItem (click)="toggleDarkSkin()">
            <span [data-feather]="'moon'" class="mr-50"></span>
            {{ layoutKeys.DARK_MODE | translate }}
          </div>
        } -->
        <div class="text-danger " ngbDropdownItem (click)="logout()">
          <span [data-feather]="'power'" [class]="'mr-50'"></span>
          {{ layoutKeys.LOGOUT | translate }}
        </div>

      </div>

    </li>
    <!--/ User Dropdown -->

    <!-- Language selection -->
    <li ngbDropdown class="nav-item dropdown dropdown-language">
      <a class="nav-link dropdown-toggle" id="dropdown-flag" ngbDropdownToggle>
        <i class="flag-icon flag-icon-{{ languageOptions[_translateService.currentLang].flag }}"></i><span
          class="selected-language">{{ languageOptions[_translateService.currentLang].title }}</span></a>
      <div ngbDropdownMenu aria-labelledby="dropdown-flag" class="  dropdown-menu-alignment">
        <a *ngFor="let lang of _translateService.getLangs()" ngbDropdownItem (click)="setLanguage(lang)">
          <i class="flag-icon flag-icon-{{ languageOptions[lang].flag }}"></i> {{ languageOptions[lang].title }}
        </a>
      </div>
    </li>
    <!--/ Language selection -->


    <!-- Notifications -->
    <li class="nav-item d-block" *ngIf="!isAdmin">
      
      <a class="nav-link nav-link-style btn" [routerLink]="appRoutesConst.usersNotifications">
      
        <span class="icon-bell ficon font-medium-5 feather"></span>
      
      </a>
    
    </li>
    <!--/ Notifications -->

    <!-- Language selection -->
    <li hidden ngbDropdown class="nav-item dropdown dropdown-language">
      <a class="nav-link dropdown-toggle" id="dropdown-flag" ngbDropdownToggle>
        <i class="flag-icon flag-icon-{{ languageOptions[_translateService.currentLang].flag }}"></i><span
          class="selected-language">{{ languageOptions[_translateService.currentLang].title }}</span></a>
      <div ngbDropdownMenu class="dropdown-menu dropdown-menu-alignment" aria-labelledby="dropdown-flag">
        <a *ngFor="let lang of _translateService.getLangs()" ngbDropdownItem (click)="setLanguage(lang)">
          @if (languageOptions[lang]) {
            <i class="flag-icon flag-icon-{{ languageOptions[lang].flag }}"></i> {{ languageOptions[lang].title }}
          }
        </a>
      </div>
    </li>
    <!--/ Language selection -->

  </ul>

</div>