import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'hideEmail'
})
export class HideEmailPipe implements PipeTransform {
  transform(email: string): string {
    const atIndex = email.indexOf('@');
    if (atIndex < 2) {
      return email;
    }

    const visiblePart = email.substring(0, 2);
    const hiddenPart = '*'.repeat(7);
    const domainPart = email.substring(atIndex);

    return visiblePart + hiddenPart + domainPart;
  }
}