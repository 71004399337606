export const locale = {
  lang: 'en',
  data: {
    MENU: {
      HOME: 'Home',

      GENERAL_SECTION: 'General',

      DASHBOARD: 'Dashboard',

      ACCOUNTS_SECTION: 'Accounts',

      ACCOUNT_SETTINGS: 'Account Settings',

      SETTINGS_SECTION: 'Settings',
      WEB_SETTINGS: 'Website Settings',

      MANAGEMENT_SECTION: 'Management',
      MENU: 'Menu',

      ORGANIZATION: 'Organization',

      SETTINGS: 'Settings',

      PLACES: 'Places',
      PRIZES: 'Prizes',
      SCHOOLS: 'Schools',
      EXAMS: 'Exams',
      USERS: 'Users',
      NOTIFICATIONS: 'Notifications',
      TEACHERS: 'Teachers',
      STUDENTS: 'Students',
      STUDENTS_RESULTS: 'Students Results',
      WINNERS: 'Winners',
      MANAGE_NOMINATIONS: 'Manage Nominations',
      MANAGE_ACCOUNTS: 'Manage Accounts',
      STATS: 'Stats',
      INSTRUCTIONS: 'Exams Information and Instructions',
    },
  },
};