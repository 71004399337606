import { formatDate } from '@angular/common';
import { Injectable } from '@angular/core';
import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { appKeys } from '../values/app-keys';
import { TranslateService } from '@ngx-translate/core';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class DateUtilsService {

  public years: number[] = [];
  public studyYears: string[] = [];
  public currentStudyYear: string = "";
  public months: number[] = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];
  public currentYear: number = -1;

  public regex = /^\d{1,2}\/\d{1,2}\/\d{4}, \d{1,2}:\d{2}:\d{2} (AM|PM)$/;

  constructor(
    private _translate: TranslateService,
  ) {
    this.fillYears();
    this._fillStudyYears();
  }

  public convertToDateOnly(dateStr: string): string {
    if (!dateStr) { return ''; }
    return formatDate(dateStr, 'yyyy-MM-dd', 'en-GB', 'en-GB');
  }

  public getTodayDate(): any {
    const date = new Date();

    let currentDay = String(date.getDate()).padStart(2, "0");
    let currentMonth = String(date.getMonth() + 1).padStart(2, "0");
    let currentYear = date.getFullYear();
    let currentDate = `${currentYear}-${currentMonth}-${currentDay}`;

    return(this.convertDateStringToNgbDateStruct(currentDate));
  }

  public getDaysInMonth(month: number): number {
    const year = new Date().getFullYear(); // Get the current year
    const date = new Date(year, month, 0); // Set the date to the next month's 0th day (which is the last day of the current month)
    return date.getDate(); // Get the day component, which represents the number of days in the month
  }

  public getDaysInMonthAsArray(month: number): number[] {
    let days = this.getDaysInMonth(month);
    let arrayOfDays: number[] = [];
    for (let i = 1; i <= days; i++) {
      arrayOfDays.push(i);
    }
    return arrayOfDays;
  }
  

  public convertDateStringToISO(dateObject: {year:number, month: number, day: number}): string {
    const { year, month, day } = dateObject;
  
    const formattedMonth = month.toString().padStart(2, '0');
    const formattedDay = day.toString().padStart(2, '0');
  
    const convertedDateString = `${year}-${formattedMonth}-${formattedDay}T00:00:00`;
  
    return convertedDateString;
  }

  public convertToDateString(date: Date, isTimeMax: boolean = false): string {
    var time = isTimeMax ? '23:59:59' : '12:00:00';
    return formatDate(date, 'yyyy-MM-ddT' + time, environment.englishLocale);
  }

  public convertToShortDateString(date: Date): string {
    return formatDate(date, 'yyyy/MM/dd', environment.englishLocale);
  }

  public convertFromNGBStructureToDateString(dateString: any): string {
    const date = new Date(dateString);
    
    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const day = date.getDate().toString().padStart(2, '0');
    
    const formattedDate = `${year}-${month}-${day}`;
    
    return formattedDate;
  }

  // public formatStrDate(date: string, friendlyFormat: boolean = false): string {
  //   let format = ${environment.dateFormat} ${environment.timeFormat};
  //   if (friendlyFormat) {
  //     if (this.convertToShortDateString(new Date()) == this.convertToShortDateString(new Date(date))) {
  //       format = environment.timeFormat;
  //     }
  //   }

  //   return formatDate(date, format, environment.englishLocale);
  // }

  public getToday(): Date {
    let date = new Date();
    let dateString = this.convertDateToISO(
      date.toString(),
    );
    return new Date(dateString);
  }

  public getYasterday(): Date {
    let date = new Date();
    date.setDate(date.getDate() - 1);
    let dateString = this.convertDateToISO(
      date.toString(),
    );
    return new Date(dateString);
  }

  public fillYears(): void {
    let firstYear = environment.firstYear;
    let currentYear = new Date().getFullYear();
    let year = firstYear;

    while (year <= currentYear) {
      this.years.push(year);

      if (year == currentYear) {
        this.currentYear = year;
      }

      year++;
    }

    this.years = this.years.reverse();
  }

  public convertDateToISO(
    dateString: string,
  ): string {
    let dt = new Date(dateString);
    var tzoffset = dt.getTimezoneOffset() * 60000;
    var localISOTime = new Date(dt.getTime() - tzoffset).toISOString();
    return localISOTime;
  }

  public isMinDate(
    dateString: string,
  ): boolean {
    return dateString.toString().includes('0001');
  }

  public disableFlatInput(
    flatDateControl: any,
  ): void {
    flatDateControl.flatpickrElement.nativeElement.children[1].setAttribute('disabled', '');
  }

  public enableFlatInput(
    flatDateControl: any,
  ): void {
    flatDateControl.flatpickrElement.nativeElement.children[1].removeAttribute('disabled');
  }

  public showDateOnly(dateStr: string) {
    if (!dateStr) { return ''; }
    return formatDate(dateStr, 'dd/MM/yyyy', 'en-GB');
  }

  public getDateOrTime(dateStr: string, noTime: boolean = false) {
    if (!dateStr) {
      return "";
    }
    let dateTime;

    if(!this.regex.test(dateStr)) {
      let date = formatDate(dateStr, "dd/MM/yyyy", "en-GB");
      let time = formatDate(dateStr, "hh:mm a", "en-GB");
  
      dateTime = `${date} ${time}`;
    }
    else {
      dateTime = dateStr;
    }

    dateTime = 
      dateTime
        .replace("AM", this._translate.instant(appKeys.AM))
        .replace("PM", this._translate.instant(appKeys.PM))
        .replace("ص", this._translate.instant(appKeys.AM))
        .replace("م", this._translate.instant(appKeys.PM));

    return dateTime;

    // if (year == curYear) {
    //   if (month == curMonth) {
    //     if (day == curDay) {
    //       if (noTime) {
    //         return this._translate.instant(appKeys.TODAY);
    //       }
    //       return time;
    //     } else {
    //       date = formatDate(dateStr, "dd/MM", "en-GB");
    //     }
    //   } else {
    //     date = formatDate(dateStr, "dd/MM", "en-GB");
    //   }
    // } else {
    //   date = formatDate(dateStr, "MM/yyyy", "en-GB");
    // }
    // return `${date} ${time}`;
  }

  public convertDateStringToNgbDateStruct(dateString: string): NgbDateStruct {
    const dateParts = dateString.split('T')[0].split('-');
    const year = parseInt(dateParts[0], 10);
    const month = parseInt(dateParts[1], 10);
    const day = parseInt(dateParts[2], 10);
    return { year, month, day };
  }

  public convertNgbDateStructToDateString(dateStruct: NgbDateStruct): string {
    const year = dateStruct.year.toString().padStart(4, '0');
    const month = dateStruct.month.toString().padStart(2, '0');
    const day = dateStruct.day.toString().padStart(2, '0');
    return `${year}-${month}-${day}`;
  }

  private _fillStudyYears(): void {
    this.studyYears = [];
    let firstYear = environment.firstYear;
    let currentYear = new Date().getFullYear();

    let year = firstYear;
    while (year <= currentYear) {
      let yearStr = year + " - ";
      let nextYear = year;
      nextYear++;
      yearStr += nextYear;
      this.studyYears.push(yearStr);
      if (year == currentYear) {
        this.currentStudyYear = yearStr;
      }
      year++;
    }
  }

  public convertTimeToSeconds(
    time: string,
  ): number {
    let hours: number;
    let minutes: number;
    let seconds: number;

    if (time.length === 6) {
      hours = Number(time.substr(0, 2));
      minutes = Number(time.substr(2, 2));
      seconds = Number(time.substr(4, 2));
    } 
    else if (time.length === 8) {
      [hours, minutes, seconds] = time.split(':').map(Number);
    } 
    else {
      throw new Error('Invalid time format');
    }

  const totalSeconds = hours * 3600 + minutes * 60 + seconds;
  return totalSeconds;
  }

  public convertSecondsToTime(
    seconds: number,
  ): string {
    const hours = Math.floor(seconds / 3600);
    const minutes = Math.floor((seconds % 3600) / 60);
    const remainingSeconds = seconds % 60;
    const time = `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:${remainingSeconds.toString().padStart(2, '0')}`;
    return time;
  }
}