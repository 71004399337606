export const appKeys = {
    SUCCESS: 'SUCCESS',
    WARNING: 'WARNING',
    ERROR: 'ERROR',
    OPERATION_FAILED: 'OPERATION_FAILED',
    OPERATION_SUCCEEDED: 'OPERATION_SUCCEEDED',

    TITLE: 'TITLE',
    
    LOADING: 'LOADING',
		
		MALE:'MALE',
		FEMALE:'FEMALE',

		NAME: 'NAME',
		NAME_REQUIRED: 'NAME_REQUIRED',

    EMAIL: 'EMAIL',
    EMAIL_REQUIRED: 'EMAIL_REQUIRED',

    FROM: 'FROM',
    TO: 'TO',

    ITEM: 'ITEM',

    NO_DATA_FOUND: 'NO_DATA_FOUND',
    DONE: 'DONE',

		ITEM_STATUS: 'ITEM_STATUS',

    ACTIONS: 'ACTIONS',

    SAVE: 'SAVE',
    SAVE_AS_COPY: 'SAVE_AS_COPY',
    NEW: 'NEW',
    CANCEL: 'CANCEL',

    DATE: 'DATE',
    SEARCH_HERE: 'SEARCH_HERE',
    SEARCH: 'SEARCH',
    CANCEL_SEARCH: 'CANCEL_SEARCH',

    FILES: 'FILES',
    UPLOAD_PHOTO: 'UPLOAD_PHOTO',
    UPLOAD_FILE: 'UPLOAD_FILE',
    RESET: 'RESET',
    UPLOAD_HELPER_TEXT: 'UPLOAD_HELPER_TEXT',
    UPLOADING: 'UPLOADING',
    UPLOAD_DONE: 'UPLOAD_DONE',
    UPLOAD_FAILED: 'UPLOAD_FAILED',

    NOTES: 'NOTES',

    CONFIRMATION: 'CONFIRMATION',
    DELETE_QUESTION_CONFIRMATION: 'DELETE_QUESTION_CONFIRMATION',
    DELETE_SUBJECT_CONFIRMATION: 'DELETE_SUBJECT_CONFIRMATION',
    DELETE_CONFIRMATION: 'DELETE_CONFIRMATION',
    RESTORE_CONFIRMATION: 'RESTORE_CONFIRMATION',
    OK: 'OK',
    BACK: 'BACK',

    EMAIL_INVALID: 'EMAIL_INVALID',

    USER_NAME: 'USER_NAME',

		MENU: 'MENU',
		EDIT_MENU: 'EDIT_MENU', 

		EDIT: 'EDIT',
    DELETE: 'DELETE',
    RESTORE: 'RESTORE',

    EXECUTE: 'EXECUTE',
    PRINT: 'PRINT',

    YEAR: 'YEAR',
    MONTH: 'MONTH',
    DAY: 'DAY',

    ALL: 'ALL',

    HOUR: 'HOUR',
    MINUTES: 'MINUTES',

    CURRENCY: 'CURRENCY',
    CURRENCY_EN: 'CURRENCY_EN',

    RECIPT: 'RECIPT',

    COST: 'COST',

    STUDY_YEAR:'STUDY_YEAR',
    
    PAID_TO_MR: 'PAID_TO_MR',
    GENTLEMAN: 'GENTLEMAN', 
    AN_AMOUNT_OF: 'AN_AMOUNT_OF',
    FOR_COURSE: 'FOR_COURSE',
    IN_GROUP: 'IN_GROUP',

    VIRTUAL_NICK_NAME: 'VIRTUAL_NICK_NAME',
    COPY: 'COPY',
    ID: 'ID',
    COPIED: 'COPIED',

    PLEASE_WAIT: 'PLEASE_WAIT',
    
    FAILURE: 'failure',

    LOCAL_EXCEPTION: 'localException',
    SERVER_EXCEPTION: 'serverException',

    SERVER_FAILURE: 'serverFailure',
    LOCAL_FAILURE: 'localFailure',
    NO_INTERNET_CONNECTION: 'noInternetConnection',

    PRICE: 'PRICE',

    DONE_SUCCESSFULLY: 'DONE_SUCCESSFULLY',

    UPLOAD_PHOTO_FAILED: 'UPLOAD_PHOTO_FAILED',
    CLICK_TO_CHOSE_IMAGE: 'CLICK_TO_CHOSE_IMAGE',
    EXPLANATION_PHOTO: 'EXPLANATION_PHOTO',
    PHOTO: 'PHOTO',
    
    UPLOAD_VIDEO_FAILED: 'UPLOAD_VIDEO_FAILED',
    CLICK_TO_CHOOSE_VIDEO: 'CLICK_TO_CHOOSE_VIDEO',
    EXPLANATION_VIDEO: 'EXPLANATION_VIDEO',
    VIDEO: 'VIDEO',

    CLICK_TO_CHOOSE_FILE: 'CLICK_TO_CHOOSE_FILE',
    UPLOAD_FILE_FAILED: 'UPLOAD_FILE_FAILED',
    EXPLANATION_FILE: 'EXPLANATION_FILE',
    FILE: 'FILE',

    FREE: 'FREE',
    AVAILABLE_WITH_PARENT: 'AVAILABLE_WITH_PARENT',
    NOT_AVAILABLE_WITH_PARENT: 'NOT_AVAILABLE_WITH_PARENT',

    ADD: 'ADD',
    REMOVE: 'REMOVE',

    YOU_CAN_SEARCH_BY_NAME: 'YOU_CAN_SEARCH_BY_NAME',
    YOU_CAN_SEARCH_OR_ADD_BY_TAG_NAME: 'YOU_CAN_SEARCH_OR_ADD_BY_TAG_NAME',

    DESCRIPTION: 'DESCRIPTION',
    BRIEF_DESCRIPTION: 'BRIEF_DESCRIPTION',
    DISPLAY_ORDER: 'DISPLAY_ORDER',
    DISPLAY_ORDER_REQUIRED: 'DISPLAY_ORDER_REQUIRED',

    LEAVE_WITHOUT_SAVING: 'LEAVE_WITHOUT_SAVING',
    LEAVE: 'LEAVE',

    AM: 'AM',
    PM: 'PM',
    TODAY: 'TODAY',

    CONTACT_US: 'CONTACT_US',
}