export const locale = {
  lang: 'en',

  data: {

    SATURDAY: 'Saturday',
    SUNDAY: 'Sunday',
    MONDAY: 'Monday',
    TUESDAY: 'Tuesday',
    WEDNESDAY: 'Wednesday',
    THURSDAY: 'Thursday',
    FRIDAY: 'Friday',

    TITLE: 'Al-Baleed Award for Scientific Excellence',
      
      MALE: 'Male',
      FEMALE: 'Female',

      NAME: 'Name',
      NAME_REQUIRED: '- Name is required.',

    EMAIL: 'Email',
    EMAIL_REQUIRED: '- Email is required.',

    SUCCESS: 'Success',
    WARNING: 'Warning',
    ERROR: 'Error',
    OPERATION_FAILED: 'Operation failed',
    OPERATION_SUCCEEDED: 'Operation succeeded',

      ITEM_STATUS: 'Item Status',

      ALL: 'All',
      SHOW_ALL: 'Show All',
      DELETED: 'Deleted',
      NOT_DELETED: 'Not Deleted',

    CONFIRMATION: 'Confirmation!',
    DELETE_QUESTION_CONFIRMATION: 'The question will be deleted from the following forms:',
    DELETE_SUBJECT_CONFIRMATION: 'The subject will be deleted from the following places:',
    DELETE_CONFIRMATION: 'Are you sure you want to delete?',
    RESTORE_CONFIRMATION: 'Are you sure you want to restore?',
    OK: 'OK',
    BACK: 'Back',

    LOADING: 'Loading data...',

    INVENTORY: 'Batch Inventory',
    MONTHPAYMENT: 'This Month Payments',
    NOTPAID: 'Unpaid Students',
    PARRECEIPT: 'Payment Receipt',
    PAYMENT: 'Payment Record',

    // Paging
    FROM: 'From',
    TO: 'To',
    ITEM: 'Item',

    NO_DATA_FOUND: 'No data found',
    DONE: 'Done',

    ACTIONS: 'Actions',

    SAVE: 'Save',
    SAVE_AS_COPY: 'Save As Copy',
    NEW: 'New',
    CANCEL: 'Cancel',

    DATE: 'Date',
    SEARCH_HERE: 'Search here...',
    SEARCH: 'Search',
    CANCEL_SEARCH: 'Cancel Search',

    FILES: 'Attachments',
    UPLOAD_PHOTO: 'Upload Photo',
    UPLOAD_FILE: 'Upload File',
    RESET: 'Reset',
    UPLOAD_HELPER_TEXT: 'Maximum size: 800KB',
    UPLOADING: 'Uploading...',
    UPLOAD_DONE: 'Uploaded',
    UPLOAD_FAILED: 'Upload failed',

    NOTES: 'Notes',

    EMAIL_INVALID: '- Please make sure to enter your email correctly',

    USER_NAME: 'User Name',

      EDIT_MENU: 'Edit Menu',

    EDIT: 'Edit',
    DELETE: 'Delete',
      RESTORE: 'Restore',

    EXECUTE: 'Execute',
    PRINT: 'Print',

    YEAR: 'Year',
    MONTH: 'Month',
    DAY: 'Day',

    STUDY_YEAR:'Study Year',

    HOUR: 'Hour',
    MINUTES: 'Minutes',

    CURRENCY: 'SYP',
    CURRENCY_EN: 'SYP',
    PAID_TO_MR: 'Paid to Mr.',
    GENTLEMAN: 'Gentleman', 
    AN_AMOUNT_OF: 'an amount of',
    FOR_COURSE: 'for the course',
    IN_GROUP: 'in group',

    RECIPT: 'Receipt',
    MARKS: 'Marks',

    ATTENDANCE_TITLE: "Attendance",

    COST: 'Cost',

    VIRTUAL_NICK_NAME: 'Client',
    COPY: 'Copy',
    ID: 'ID',
    COPIED: 'Copied',

    PLEASE_WAIT: 'Please wait',

    PRICE: 'Price',

    DONE_SUCCESSFULLY: 'Done successfully',

    UPLOAD_PHOTO_FAILED: 'Failed to upload photo',
    CLICK_TO_CHOSE_IMAGE: 'Click to choose an image',
    EXPLANATION_PHOTO: 'Photo',
    PHOTO: 'Photo',

    UPLOAD_VIDEO_FAILED: 'Failed to upload video',
    CLICK_TO_CHOOSE_VIDEO: 'Click to choose a video',
    EXPLANATION_VIDEO: 'Video',
    VIDEO: 'Video',

    UPLOAD_FILE_FAILED: 'Failed to upload file',
    CLICK_TO_CHOOSE_FILE: 'Click to choose a file',
    EXPLANATION_FILE: 'File',
    FILE: 'File',

    FREE: 'Free',
    AVAILABLE_WITH_PARENT: 'Available with parent',
    NOT_AVAILABLE_WITH_PARENT: 'Not available with parent',

    ADD: 'Add',
    REMOVE: 'Remove',

    YOU_CAN_SEARCH_BY_NAME: 'You can search by name',
    YOU_CAN_SEARCH_OR_ADD_BY_TAG_NAME: 'You can search or add by tag name',

    DESCRIPTION: 'Description',
    BRIEF_DESCRIPTION: 'Brief Description',
    DISPLAY_ORDER: 'Display Order',
    DISPLAY_ORDER_REQUIRED: '- Display order is required.',

    LEAVE_WITHOUT_SAVING: 'Do you want to leave without saving the changes?',
    LEAVE: 'Are you sure you want to leave the page?',

    AM: 'am',
    PM: 'pm',
    TODAY: 'Today',

    CONTACT_US: 'Contact us',
  },
};
