export const locale = {
  lang: 'ar',
  data: {
    MENU: {
      HOME: 'الرئيسية',

      GENERAL_SECTION: 'عام',

      DASHBOARD: 'الرئيسية',

      ACCOUNTS_SECTION: 'الحسابات',

      ACCOUNT_SETTINGS: 'إعدادات الحساب',

      SETTINGS_SECTION: 'الإعدادات',
      WEB_SETTINGS: 'إعدادات الموقع',

      MANAGEMENT_SECTION: 'إدارة',
      MENU: 'القائمة',

      ORGANIZATION: 'التنظيم',

      SETTINGS: 'الإعدادات',

      PLACES: 'المراكز',
      PRIZES: 'الجوائز',
      SCHOOLS: 'المدارس',
      EXAMS: 'الاختبارات التجريبية',
      USERS: 'المستخدمين',
      NOTIFICATIONS: 'الإشعارات',
      TEACHERS: 'الأساتذة',
      STUDENTS: 'الطلاب',
      STUDENTS_RESULTS: 'نتائج الطلاب',
      WINNERS: 'الفائزون و الفائزات',
      MANAGE_NOMINATIONS: 'ترشيح الطلاب',
      MANAGE_ACCOUNTS: 'تسجيلات الموقع',
      STATS: 'الإحصائيات',
      INSTRUCTIONS: 'معلومات و تعليمات الاختبارات',
    },
  },
};
