export const locale = {
  lang: 'ar',

  data: {
    DASHBOARD: {
      MOBILE: 'جوال الطالب/ة',
      FATHER_MOBILE: 'جوال الأب',
      MOTHER_MOBILE: 'جوال الأم',
      PAID_AMOUNT: 'المبلغ المدفوع',
      REMAIN_AMOUNT: 'المبلغ المتبقي',
      AMOUNT_SHOULD_PAID: 'الواجب دفعه',
      LAST_PAYMENT_DATE: 'تاريخ أخر دفعة',
      PAYMENTS_COUNT: 'عدد الدفعات',
      PAYMENTS: 'الدفعات',
      NO_PAYMENTS: 'لا يوجد دفعات',
      STATISTICS: 'الإحصائيات',
  
      GROUP: 'الفئة',
      SUBJECT: 'المادة',
      TEACHER_NAME: 'الأستاذ',
      TYPE: 'النوع',
      DATE: 'التاريخ',
      AD_DATE: 'تاريخ تسليم الإجابات',
      
      STUDENTS: 'الطلاب',
      TEACHERS: 'الأساتذة',
      GROUPS: 'الفئات',
      TOTAL_OF: 'عدد ',
      NOT_PAID_STUDENTS: 'الطلاب غير المسددين',
      NOT_DELIVERED_EXAMS: 'المذاكرات غير المستلمة',
  
      ADD_PAYMENT: 'إضافة دفعة',
    }
	}
};
