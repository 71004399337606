export const appRoutesConstants = {
  meta: "/meta",

  dashboard: "/dashboard",
  landingPage: "/",

  places: "/places",
  prizes: "/prizes",
  schools: "/schools",
  exams: "/exams",
  users: "/users",
  notifications: "/notifications",
  stats: "/stats",
  prizesWithLevels: "/prizes-with-levels",
  winners: "/winners",
  instructions: "/instructions",
  usersNotifications: "/users-notifications",
  manageNominations: "/manage-nominations",
  nominationDetails: "/manage-nominations/nomination-details",
  
  manageAccounts: "/manage-accounts",
  accountDetails: "/manage-accounts/account-details",
  
  teacherDashboard: "/teacher-dashboard",
  TeacherDashboardNominateStudents: "/teacher-dashboard/nominate-students",
  teacherDashboardStudentsResults: "/teacher-dashboard/students-results",
  teacherDashboardProfile: "/teacher-dashboard/profile",
  
  studentDashboard: "/student-dashboard",
  studentDashboardTestExam: "/student-dashboard/test-exam",
  studentDashboardProfile: "/student-dashboard/profile",
  studentDashboardStudentResults: "/student-dashboard/results",

  studentsResults: 'students-results',
  studentsResultsWinners: 'students-results/winners',
  
  notAuthorized: "/miscellaneous/not-authorized",
  pageNotFound: "/miscellaneous/error",

  login: "/auth/login",
  forgotPassword: "/auth/forgot-password",
  resetPassword: "/auth/reset-password",
  confirmEmail: "/auth/confirm-email",
  register: "/auth/register",
  confirmPhone: "/auth/confirm-phone",

  accountSettings: "/account-settings",

  comulatives: "/comulatives/list",
  balance: "/comulatives/balance",
  balanceStats: "/comulatives/balance-stats",
  clientsComulativeList: "/comulatives/clients/list",
  statements: "/app/statements",
  currencies: "/currencies/list",
  constraintList: "/app/constraint-list", // same to constraint-upsert
  advancedSearch: "/app/advanced-search",
  eventsHistory: "/app/events-history",
  clients: "/clients/list/general",
  permissions: "/permissions/list",
  debtorsComulatives: "/debtors/list",
  fundsManage: "/clients/list/funds",
  fundsStatements: "/app/funds-statements",

  settings: "/settings",

  ownerOfCopies: "/owner-of-copies/list",
  features: "/app/features/list",
  subscriptions: "/app/features/subscriptions",
  mySubscriptions: "/app/features/my-subscriptions",

  bills: "/bills/list",
};
