export const locale ={
    lang: 'en',
    data: {
    NAVBAR:{
    LOGOALT: 'Company Logo',
    PROFILE_LOGO_ALT: 'Profile Image',
    ACCOUNT_SETTINGS: 'Account Settings',
    LIGHT_MODE: 'Light Mode',
    DARK_MODE: 'Dark Mode',
    LOGOUT: 'Logout'
    },

    NAVBAR_SEARCH: {
        SEARCH: 'Search',
    }
}
}