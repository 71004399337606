import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';

import { appRoutesConstants } from 'app/@core/values/app-routes-constants';
// import { MetaService } from 'app/main/meta/meta.service';

@Injectable({ providedIn: 'root' })
export class AuthGuard implements CanActivate {
  /**
   *
   * @param {Router} _router
   * @param {AuthService} _authService
   */
  constructor(
    private _router: Router,
    // private _metaService: MetaService,
  ) { }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ) {
    // const currentUser = this._authService.currentUserValue;

    // if (currentUser) {
    //   // Check if route is restricted by role
    //   if (
    //     route.data.roles &&
    //     route.data.roles.indexOf(currentUser.roles[0]) === -1
    //   ) {
    //     // Role not authorised so redirect to not-authorized page
    //     this._router.navigate(
    //       [
    //         appRoutesConstants.notAuthorized
    //       ],
    //     );

    //     return false;
    //   }

    //   // if (!this._metaService.uiData.isMetaDataReady) {
    //   //   this._router.navigate(
    //   //     [
    //   //       appRoutesConstants.meta,
    //   //     ],
    //   //     {
    //   //       queryParams: {
    //   //         returnUrl: state.url,
    //   //       },
    //   //     },
    //   //   );

    //   //   return false;
    //   // }

    //   // Authorised and meta data ready, so return true
    //   return true;
    // }

    // Not logged in so redirect to login page with the return url
    // this._router.navigate(
    //   [
    //     appRoutesConstants.login,
    //   ],
    //   {
    //     queryParams: {
    //       returnUrl: state.url,
    //     },
    //   },
    // );

    return true;
  }
}
