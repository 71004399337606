import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { ToastrService } from "ngx-toastr";
import { Observable } from "rxjs/internal/Observable";
import { appConstants } from "../values/app-constants";
import { appKeys } from "../values/app-keys";
import { BehaviorSubject } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class AppUtilsService {
  // ------------------------------- Public Fields -------------------------------


  // ------------------------------- Private Fields -------------------------------

  private _emailPattern: RegExp =
    /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;

  // ------------------------------- Constructor -------------------------------

  constructor(
    private _httpClient: HttpClient,
    private _toastrService: ToastrService,
    private _translateService: TranslateService,
  ) {
  }

  // ------------------------------- Public Functions -------------------------------

  public convertToEnglishDigitsStr(
    str: string,
  ): string {
    if(!str) {
      return '';
    }
    const persianDigits = [/۰/g, /۱/g, /۲/g, /۳/g, /۴/g, /۵/g, /۶/g, /۷/g, /۸/g, /۹/g];
    const englishDigits = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9'];
  
    for (let i = 0; i < persianDigits.length; i++) {
      str = 
        str
        .replace(
          persianDigits[i], 
          englishDigits[i],
        );
    }
  
    return str;
  }

  public validateEmail(
    email: string,
  ): boolean {
    return this
      ._emailPattern
      .test(
        email,
      );
  }

  public isValidUrl(
    value: string,
  ): boolean {
    let url: URL;
    try {
      url = new URL(value);
    } catch (_) {
      return false;
    }
    return url.protocol === "http:" || url.protocol === "https:";
  }

  public loadImage(
    url: string,
  ): Observable<Blob> {
    return this._httpClient.get<Blob>(url);
  }

  public copyCode(
    code: string,
  ): void {
    const selectBox = document.createElement("textarea");
    selectBox.style.position = "fixed";
    selectBox.value = code;
    document.body.appendChild(selectBox);
    selectBox.focus();
    selectBox.select();
    document.execCommand("copy");
    document.body.removeChild(selectBox);

    let copied: string = this._translateService.instant(appKeys.COPIED);
    this._toastrService.success(copied, "", appConstants.toastrConfig);
    // setTimeout(() => {
    //   this._uiData.copyCodeStatus = false;
    // }, 500);
    // this._uiData.copyCodeStatus = true;
  }

  // ------------------------------- Private Functions -------------------------------
}
